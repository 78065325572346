import { IEnvironmentConfig } from '@models/environment';

export const environment: IEnvironmentConfig = {
  production: true,
  errorLog: true, // logs every JS error to api/errors
  appUrl: 'https://www.cigarscanner.com',
  apiUrl: 'https://api.cigarscanner.com/api',
  mobileBreakpoint: 992,
  googleMapsKey: 'AIzaSyACPN7gPsZFhH9paytLRk_sDlgXK7gnIWA',
  googleTagManagerId: 'G-J8RQ29J4BC',
  toastPosition: 'bottom',
  toastDuration: 3000,
  firebase: {
    apiKey: 'AIzaSyDe4ZaAHnFRhTz9qer28f-S6vnoMd0hcmw',
    authDomain: 'cigarscanner3-1491304216098.firebaseapp.com',
    databaseURL: 'https://cigarscanner3-1491304216098.firebaseio.com',
    projectId: 'cigarscanner3-1491304216098',
    storageBucket: 'cigarscanner3-1491304216098.appspot.com',
    messagingSenderId: '853118462828',
    appId: '1:853118462828:web:2b1f278087d898f4',
    measurementId: 'G-9HMP9ZBS9F',
  },
  pageLimit: 20,
  imports: [],
  routes: [],
  maxImageUploadSize: 10 * 1024 * 1024, // 10MB
};
